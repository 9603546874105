import dayjs from 'dayjs';
import {
    PriceHistoryModel,
    SubscriptionModel,
    SubscriptionUserModel,
} from 'src/@types/subscription-service-api';
import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import {
    DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE,
    editableSubscriptionStatuses,
    SubscriptionStatus,
    SUBSCRIPTION_RENEWAL_DAYS,
    SubscriptionAction,
    SubscriptionPaymentType,
    SubscriptionType,
    UdbManageSectionModal,
    UdbManageSubscriptionActions,
} from 'src/constants';

export const isPriceHistoryAllowedToRenew = ({ isPaid, contractSigned }: PriceHistoryModel) => {
    return isPaid || contractSigned;
};

export const isRenewalDisabled = (
    expirationDate: string,
    priceHistoryRecord?: PriceHistoryModel,
) => {
    const today = dayjs();
    const renewalPeriodStartDate = dayjs(expirationDate).subtract(SUBSCRIPTION_RENEWAL_DAYS, 'days');

    const isRenewalPeriodStartedToday = today.isSame(renewalPeriodStartDate, 'days');
    const isRenewalPeriodStartedAfterToday = today.isAfter(renewalPeriodStartDate, 'days');
    const isInRenewalPeriod = isRenewalPeriodStartedAfterToday || isRenewalPeriodStartedToday;

    const isRenewalForbidden = isInRenewalPeriod && (
        priceHistoryRecord && isPriceHistoryAllowedToRenew(priceHistoryRecord)
    );

    return !isInRenewalPeriod || isRenewalForbidden;
};

export const getUpcomingRenewalAction = (
    { expirationDate, status, type }: SubscriptionModel,
    priceHistoryRecord?: PriceHistoryModel,
) => {
    const excludedStatusesForUpcomingRenewal = [
        SubscriptionStatus.SERVICE_ENDED,
        SubscriptionStatus.TERMINATED,
        SubscriptionStatus.PAUSED,
    ];

    if (excludedStatusesForUpcomingRenewal.includes(status) || type !== SubscriptionType.ENTERPRISE) {
        return;
    }

    const isDisabled = isRenewalDisabled(expirationDate, priceHistoryRecord);

    return {
        [UdbManageSubscriptionActions.upcomingRenewal]: {
            label: 'Upcoming renewal',
            disabled: isDisabled,
            tooltipTitle: isDisabled ? 'This functionality will become available 90 days before the renewal date' : '',
        },
    };
};

export const getUdbManageSubscriptionActionsList = (
    isActionAllowed: (actionKey: SubscriptionAction) => boolean,
    paidSubscription?: SubscriptionModel,
    priceHistoryRecord?: PriceHistoryModel,
    canManage?: boolean,
): Partial<Record<UdbManageSubscriptionActions, ManageActionType>> => {
    const {
        status,
        type,
        paymentType,
    } = paidSubscription || {};

    const isActive = status === SubscriptionStatus.ACTIVE;
    const hideUpdateGracePeriod =
        type === SubscriptionType.INSIDER && paymentType === SubscriptionPaymentType.STRIPE_MANAGED;

    if (!canManage) {
        return {
            [UdbManageSubscriptionActions.getUsageData]: { label: 'Get usage data' },
        };
    }

    return {
        ...(isActionAllowed(SubscriptionAction.CANCEL) && { [UdbManageSubscriptionActions.cancelSubscription]: { label: 'Cancel subscription' } }),
        ...(isActionAllowed(SubscriptionAction.RESTORE) && { [UdbManageSubscriptionActions.restoreSubscription]: { label: 'Restore subscription' } }),
        ...(isActionAllowed(SubscriptionAction.PAUSE) && { [UdbManageSubscriptionActions.pauseSubscription]: { label: 'Pause subscription' } }),
        ...(isActionAllowed(SubscriptionAction.RESUME) && { [UdbManageSubscriptionActions.resumeSubscription]: { label: 'Resume subscription' } }),
        ...(isActionAllowed(SubscriptionAction.TERMINATE) && { [UdbManageSubscriptionActions.terminateSubscription]: { label: 'Terminate subscription' } }),
        [UdbManageSubscriptionActions.getUsageData]: { label: 'Get usage data' },
        ...(isActive && !hideUpdateGracePeriod && { [UdbManageSubscriptionActions.updateGracePeriod]: { label: 'Update grace period' } }),
        ...(paidSubscription && getUpcomingRenewalAction(paidSubscription, priceHistoryRecord)),
    };
};

export const getManagerSectionActionsList = (
    managersData: SubscriptionUserModel[],
    { status }: SubscriptionModel,
): Record<UdbManageSectionModal, ManageActionType> => {
    const hasManagers = !!managersData.length;
    const disabledDueToSubscriptionStatus = !editableSubscriptionStatuses.includes(status);
    const tooltipTitle = disabledDueToSubscriptionStatus ? DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE : '';

    return {
        [UdbManageSectionModal.addManager]: {
            label: 'Add manager',
            disabled: disabledDueToSubscriptionStatus,
            tooltipTitle,
        },
        [UdbManageSectionModal.changeOwner]: {
            label: 'Change owner',
            disabled: !hasManagers || disabledDueToSubscriptionStatus,
            tooltipTitle: !hasManagers ? 'Please add at least one manger first' : tooltipTitle,
        },
    };
};

import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { GridColDef } from 'src/@types/data-grid';
import { defaultHeaderRenderer } from 'src/components/DataGrid/components/defaultHeaderRenderer';
import { defaultCellRenderer } from 'src/components/DataGrid/components/defaultCellRenderer';
import { stripeLinkRenderer, ManageEntityLink } from 'src/pages/udb/Subscriptions/components';
import { getSubscriptionPaymentStatus } from 'src/services/subscription-service-getters';
import { CustomAttributesKeys, SearchSubscriptionsModel, SubscriptionModel } from 'src/@types/subscription-service-api';
import { dateValueFormatter, defaultValueFormatter } from 'src/services/grid-formatters';
import { SubscriptionStatus, paymentTypeMapping, subscriptionStatusMapping, SubscriptionType } from 'src/constants';
import { SubscriptionStatus as SubscriptionStatusComponent } from 'src/pages/udb/Subscriptions/components/SubscriptionStatus';
import CopyCellRenderer from 'src/components/DataGrid/components/CopyCell/Renderer';

const COLUMN_HEADERS = {
    email: 'Email address',
    fullName: 'Full name',
    company: 'Company',
    activationDate: 'Activation date',
    expirationDate: 'Expiration date',
    gracePeriodEndDate: 'Grace period end date',
    paymentType: 'Payment type',
    stripeCustomer: 'Stripe customer',
    stripeSubscription: 'Stripe subscription',
    subscriptionStatus: 'Subscription status',
    cmStatus: 'CM status',
    manage: 'Manage',
    userCount: 'Users',
    domains: 'Domains',
};

export const emailGetter = ({ row }: { row: SearchSubscriptionsModel }): string => (
    row.owner?.user.email ?? ''
);

export const fullNameGetter = ({ row }: { row: SearchSubscriptionsModel }): string => (
    row.owner?.user.fullName ?? ''
);

export const companyGetter = ({ row }: { row: SearchSubscriptionsModel }): string => (
    row.owner?.user.companyName ?? ''
);

export const customAttributesGetter = (key: CustomAttributesKeys) => (
    ({ row }: { row: SubscriptionModel }): string => (
        row.customAttributes?.[key] as string
    )
);

export const cmStatusGetter = ({ row }: { row: SearchSubscriptionsModel }): string => (
    row.owner?.user.cmStatus ?? ''
);

export const paymentTypeGetter = ({ value }: { value: keyof typeof paymentTypeMapping }): string => (
    value && paymentTypeMapping[value]
);

export const subscriptionStatusGetter = ({ value }: { value: SubscriptionStatus }): string => (
    value && subscriptionStatusMapping[value]
);

export const domainGetter = ({ value }: { value: { domain: string }[] }): string => (
    value?.map(({ domain }) => domain).join(value.length > 1 ? ', ' : '')
);

export const stripeCustomersLinkRenderer = ({ formattedValue }: GridRenderCellParams) => (
    stripeLinkRenderer('customers', formattedValue)
);

export const stripeSubscriptionsLinkRenderer = ({ formattedValue }: GridRenderCellParams) => (
    stripeLinkRenderer('subscriptions', formattedValue)
);

export const ManageLinkRenderer = ({ row }: GridRenderCellParams) => (
    <ManageEntityLink id={row.uuid} type="subscription" />
);

export const subscriptionStatusRenderer = ({ value, row }: GridRenderCellParams<SearchSubscriptionsModel>) => {
    const paymentStatus = getSubscriptionPaymentStatus(row as SubscriptionModel);

    return (
        <SubscriptionStatusComponent
            value={value}
            paymentStatus={paymentStatus}
        />
    );
};

export const defaultColumns: (GridColDef & { columnOrder: number })[] = [
    {
        field: 'owner.user.email',
        headerName: COLUMN_HEADERS.email,
        minWidth: 240,
        flex: 1,
        sortable: true,
        valueFormatter: defaultValueFormatter,
        valueGetter: emailGetter,
        columnOrder: 1,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.email),
    },
    {
        field: 'owner.user.fullName',
        headerName: COLUMN_HEADERS.fullName,
        minWidth: 235,
        flex: 1,
        sortable: true,
        valueFormatter: defaultValueFormatter,
        valueGetter: fullNameGetter,
        columnOrder: 2,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.fullName),
    },
    {
        field: 'owner.user.companyName',
        headerName: COLUMN_HEADERS.company,
        minWidth: 180,
        flex: 1,
        sortable: true,
        valueFormatter: defaultValueFormatter,
        valueGetter: companyGetter,
        columnOrder: 3,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.company),
    },
    {
        field: 'activationDate',
        headerName: COLUMN_HEADERS.activationDate,
        minWidth: 160,
        flex: 1,
        sortable: true,
        type: 'date',
        valueFormatter: dateValueFormatter,
        columnOrder: 6,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.activationDate),
    },
    {
        field: 'expirationDate',
        headerName: COLUMN_HEADERS.expirationDate,
        minWidth: 160,
        flex: 1,
        sortable: true,
        type: 'date',
        valueFormatter: dateValueFormatter,
        columnOrder: 7,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.expirationDate),
    },
    {
        field: 'gracePeriodEndDate',
        headerName: COLUMN_HEADERS.gracePeriodEndDate,
        minWidth: 160,
        flex: 1,
        sortable: true,
        type: 'date',
        valueFormatter: dateValueFormatter,
        columnOrder: 8,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.gracePeriodEndDate),
    },
    {
        field: 'paymentType',
        headerName: COLUMN_HEADERS.paymentType,
        minWidth: 120,
        flex: 1,
        sortable: false,
        valueFormatter: defaultValueFormatter,
        valueGetter: paymentTypeGetter,
        renderCell: defaultCellRenderer,
        columnOrder: 9,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.paymentType),
    },
    {
        field: 'stripeCustomerId',
        headerName: COLUMN_HEADERS.stripeCustomer,
        minWidth: 180,
        flex: 1,
        sortable: false,
        valueFormatter: defaultValueFormatter,
        valueGetter: customAttributesGetter('stripeCustomerId'),
        columnOrder: 10,
        renderCell: stripeCustomersLinkRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.stripeCustomer),
    },
    {
        field: 'stripeSubscriptionId',
        headerName: COLUMN_HEADERS.stripeSubscription,
        minWidth: 200,
        flex: 1,
        sortable: false,
        valueFormatter: defaultValueFormatter,
        valueGetter: customAttributesGetter('stripeSubscriptionId'),
        columnOrder: 11,
        renderCell: stripeSubscriptionsLinkRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.stripeSubscription),
    },
    {
        field: 'status',
        headerName: COLUMN_HEADERS.subscriptionStatus,
        minWidth: 275,
        flex: 1,
        sortable: false,
        valueFormatter: defaultValueFormatter,
        valueGetter: subscriptionStatusGetter,
        renderCell: subscriptionStatusRenderer,
        columnOrder: 12,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.subscriptionStatus),
    },
    {
        field: 'cmStatus',
        headerName: COLUMN_HEADERS.cmStatus,
        minWidth: 152,
        flex: 1,
        sortable: false,
        valueFormatter: defaultValueFormatter,
        valueGetter: cmStatusGetter,
        renderCell: defaultCellRenderer,
        columnOrder: 13,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.cmStatus),
    },
    {
        field: 'manage',
        headerName: COLUMN_HEADERS.manage,
        width: 106,
        sortable: false,
        valueFormatter: defaultValueFormatter,
        renderCell: ManageLinkRenderer,
        columnOrder: 14,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.manage),
        sticky: true,
    },
];

export const getSubscriptionDataGridColumns = (type: SubscriptionType): readonly GridColDef[] => {
    if (type === SubscriptionType.ENTERPRISE) {
        return [
            ...defaultColumns,
            {
                field: 'userCount',
                headerName: COLUMN_HEADERS.userCount,
                minWidth: 100,
                flex: 1,
                sortable: true,
                columnOrder: 4,
                type: 'number',
                align: 'left',
                headerAlign: 'left',
                renderCell: defaultCellRenderer,
                renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.userCount),
            },
            {
                field: 'domains',
                headerName: COLUMN_HEADERS.domains,
                minWidth: 150,
                flex: 1,
                sortable: false,
                valueGetter: domainGetter,
                columnOrder: 5,
                renderCell: CopyCellRenderer,
                renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.domains),
            },
        ].sort(
            (a, b) => (a.columnOrder - b.columnOrder),
        ) as (GridColDef & { columnOrder: number })[];
    }

    return defaultColumns;
};

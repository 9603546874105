import { useEffect, useState } from 'react';
import { getSubscriptionActions } from 'src/services/subscription-service-api';
import { getProfileSubscriptionActions } from 'src/services/sso-api';
import { SubscriptionActions } from 'src/@types/subscription-service-api';
import { useReloadPage, useSnackbarMessage } from 'src/hooks';
import { SnackbarMessageVariants, SubscriptionAction } from 'src/constants';

export const useSubscriptionActionProvider = (
    subscriptionUUID: string,
    mode: 'udb' | 'profile',
) => {
    const [actions, setActions] = useState<SubscriptionActions>();
    const { addMessage } = useSnackbarMessage();
    const { pageReloadCount } = useReloadPage();

    const prepareAdditionalActionKeys = (actionRecord: SubscriptionActions) => {
        const updatedActions: Partial<SubscriptionActions> = {};
        Object.entries(actionRecord).forEach(([key, actionData]) => {
            const updatedKey = key.startsWith('subscription.') ? key : `subscription.${key}`;
            updatedActions[updatedKey as SubscriptionAction] = actionData;
        });
        return updatedActions as SubscriptionActions;
    };

    const fetchSubscriptionActions = async () => {
        const fetcher = mode === 'udb' ? getSubscriptionActions : getProfileSubscriptionActions;
        return fetcher(subscriptionUUID)
            .then(({ actions: fetchedActions }) => fetchedActions)
            .then(prepareAdditionalActionKeys)
            .then(setActions)
            .catch(() => {
                addMessage('Failed to load subscription actions', SnackbarMessageVariants.WARNING);
            });
    };

    const isActionAllowed = (actionKey: SubscriptionAction): boolean => {
        return !!actions?.[actionKey]?.allowed;
    };

    const getActionErrors = (actionKey: SubscriptionAction) => {
        return actions?.[actionKey]?.errors || null;
    };

    useEffect(() => {
        fetchSubscriptionActions();
    }, [subscriptionUUID, mode, pageReloadCount]);

    return {
        isActionAllowed,
        getActionErrors,
    };
};
